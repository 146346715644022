<template>
	<div id="app">
		<div class="layout" :class="{'layout_overflow-hidden': popupVisible}">
			<Bg />
			<div class="header">
				<div class="header__logo">
					<a href="https://mail.ru/" target="_blank">
						<img src="./assets/img/logo.svg" alt="">
					</a>
				</div>
				<!--div class="header__menu">
					<a href="">О проекте</a>
					<a href="">Пожелания</a>
				</div-->
			</div>
			<div class="title" :class="{'title_hidden': hideTitle}">
				<p>каждый год на 8 Марта женщины получают десятки пожеланий — мы решили спросить, чего они хотят на самом деле.</p>
			</div>
			<a href="" class="play-button" @click.prevent="showVideo = true"></a>
			<a href="" class="button button_play" @click.prevent="showVideo = true">Посмотреть видео</a>
			<a href="" class="button button_no-bg" @click.prevent="popupVisible = true">Пожелать себе</a>
			<div class="copy">©Mail.ru {{ currentYear }}</div>
			<div class="popup" v-if="popupVisible">
				<div class="popup__box">
					<span class="popup__close" @click="popupVisible = false"></span>
					<MessageBox />
				</div>
			</div>
		</div>
		<div class="video" v-if="showVideo">
			<span class="video__close" @click="showVideo = false"></span>
			<Loader/>
			<iframe src="//vk.com/video_ext.php?oid=-39554210&id=456239187&hash=f451de48fac11a83&hd=2" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
		</div>
	</div>
</template>

<script>
import Bg from './components/Bg.vue';
import MessageBox from './components/MessageBox.vue';
import Loader from './components/Loader.vue';

export default {
	name: 'App',
	components: {
		Bg,
		MessageBox,
		Loader
	},
	data:  function (){
		return {
			popupVisible: false,
			showVideo: false,
			hideTitle: false,
      currentYear: new Date().getFullYear()
		}
	},
	created: function() {
		window.addEventListener('keydown', (e) => {
			if (e.key == 'Escape') {
				this.popupVisible = false;
				this.showVideo = false;
			}
		});
		this.$root.$on('title', (variable) => {
			this.hideTitle = variable;
		})
	}
}
</script>

<style lang="scss">
@import './assets/scss/style.scss';
</style>
