<template>
	<div>
		<div class="color-chooser">
			<span 
				v-for="color in colors"
				class="color-chooser__item"
				v-bind:key="color.id"
				v-bind:class="{'color-chooser__item_current': color.id == currentId}"
				@click="currentId = color.id; currentImage = color.image; currentColor = color.main; rerender();"
				>
				<span 
					class="color-chooser__item-inner" 
					v-bind:style="{background: color.main, 'border-color': color.accent}"
					>
					&nbsp;
				</span>
			</span>
		</div>
		<div class="message-box" v-bind:style="{background: currentColor}">
			<img :src="require('../assets/img/cards/' + currentImage + '.png')" alt="" class="message-box__image" v-if="showImage">
			<span class="message-box__hashtag">#femailru</span>
			<label class="message-box__text" v-bind:class="{'message-box__text_focused': textFocused}">
				<div class="message-box__title">
					<h2>{{ textTitle }}</h2>
					<div class="message-box__alert message-box__alert_empty" v-if="text == ''">Введите текст своего пожелания и выберите фон</div>
				</div>
				<textarea-autosize
					class="message-box__textarea"
					ref="textarea"
					v-model="text"
					:min-height="0"
					:maxlength="maxlength"
					@blur.native="onBlur"
					@focus.native="onFocus"
					@keydown.native="keyPress"
					@input.native="text= $event.target.value"
					@keyup.native="keyUp"
				/>
			</label>
			<div class="message-box__info">
				<span class="message-box__alert message-box__alert_counter" v-if="(this.maxlength - this.text.length) < 39">Пожелайте только самое важное</span>
				<span class="message-box__counter">{{ maxlength - text.length}}</span>
			</div>
			<div class="message-box__alert message-box__alert_enter" v-if="showEnterAlert">Увы, мы не можем переносить строку</div>
		</div>
		<div class="share">
			<div v-if="!showShareAlert">
				<h3>Рассказать друзьям</h3>
				<div class="share__buttons">
					<a @click.prevent="share('https://vk.com/share.php?url=')" href="" class="share__button share__button_vk">VK</a>
					<a @click.prevent="share('https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=')" href="" class="share__button share__button_ok">OK</a>
					<!--a @click.prevent="share('https://www.facebook.com/sharer/sharer.php?u=')" href="" class="share__button share__button_fb">FB</a>
					<a @click.prevent="share('https://twitter.com/intent/tweet?url=')" href="" class="share__button share__button_tw">TW</a-->
					<button 
						v-clipboard="() => link"
						v-clipboard:success="clipboardSuccessHandler"
						v-clipboard:error="clipboardErrorHandler"
						:class="[linkCopied ? 'share__button share__button_copied' : 'share__button share__button_link']"
						>
						Copy to clipboard
					</button>
				</div>
			</div>
			<div class="share__alert" v-if="showShareAlert">
				<h3>Вы не ввели текст для своего пожелания, хотите ввести его сейчас?</h3>
				<button @click.prevent="focusField" href="" class="share__agree-button">Да</button>
				<button @click.prevent="openSharingPopup" href="" class="share__agree-button share__agree-button_gray">Нет</button>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize';
import Clipboard from 'v-clipboard';
import {encode} from '../utils/base64';

Vue.use(TextareaAutosize)
Vue.use(Clipboard)

export default {
	name: 'MessageBox',
	components: {
	},
	props: {
	},
	data() {
		return {
			colors: [
				{id: 1, main: "#FFB1B1", accent: "#FF7C7C", image: 1},
				{id: 2, main: "#FFC9B1", accent: "#FFA076", image: 2},
				{id: 3, main: "#FFDAA4", accent: "#FFC062", image: 3},
				{id: 4, main: "#AEBABB", accent: "#849092", image: 4},
				{id: 5, main: "#C8FDAF", accent: "#8ADA64", image: 5},
				{id: 6, main: "#9ACEFF", accent: "#6BAEED", image: 6},
				{id: 7, main: "#CFC3FF", accent: "#9985EB", image: 7}
			],
			currentId: 1,
			currentImage: 1,
			currentColor: '#FFB1B1',
			textTitle: "На 8 Марта я желаю себе...",
			text: '',
			textFocused: false,
			maxlength: 100,
			linkCopied: false,
			showShareAlert: false,
			showEnterAlert: false,
			showImage: true,
			currentSharingProviderUrl: ''
		}
	},
	computed: {
		link() {
			return window.location.origin + (this.text === '' ? '/' : `/?share_data=${encode(this.currentId + ',' + this.text)}`);
		},
	},
	methods: {
		onFocus() {
			this.textFocused = true;
		},
		onBlur() {
			this.textFocused = false;
		},
		keyPress(event) {
			if(event.keyCode == '13') {
				event.preventDefault();
				this.showEnterAlert = true;
			}
		},
		keyUp() {
			if (this.text.length >= this.maxlength){
				this.refocus();
			}
		},
		share(sharingUrl) {
			this.currentSharingProviderUrl = sharingUrl;
			if (this.text === '') {
				this.showShareAlert = true;
				return false;
			}
			this.openSharingPopup();
		},
		openSharingPopup() {
			window.open(this.currentSharingProviderUrl + encodeURIComponent(this.link), '', 'toolbar=0,status=0,resizable=1,width=600,height=500');
		},
		clipboardSuccessHandler () {
			this.linkCopied = true;
		},
		clipboardErrorHandler () {
			alert('извините, невозможо скопировать ссылку')
		},
		rerender() {
			this.showImage = false;
			this.$nextTick(() => {
				this.showImage = true
				this.$nextTick(() => {})
			})
		},
		refocus() {
			this.$refs.textarea.$el.blur()
			this.$nextTick(() => {
				this.$refs.textarea.$el.focus()
				this.$nextTick(() => {})
			})
		},
		focusField() {
			this.showShareAlert = false;
			this.$nextTick(() => this.$refs.textarea.$el.focus());
		},
		randomizeCards() {
			const rnd = Math.floor(Math.random() * this.colors.length) + 1;
			this.currentId = rnd;
			this.currentImage = rnd;
			this.currentColor = this.colors[rnd - 1].main;
		},
	},
	created() {
		this.focusField();
		this.randomizeCards();
	},
	watch: {
		text() {
			if (this.text != '') {
				this.textTitle = 'На 8 Марта я желаю себе';
				this.showShareAlert = false;
			} else {
				this.textTitle = 'На 8 Марта я желаю себе...';
			}
			this.showEnterAlert = false;
			this.text = this.text.replace( /\r?\n|\r/g, '' );
		},
	}
}
</script>

<style scoped>

</style>